import { BlackButton, GreyButton } from 'components/button/Buttons'
import { TextRebrandHeader } from 'components/text/TextRebrand'
import { smallScreenWidth, useLanding } from 'hooks/hooks'
import { FC } from 'react'
import styled from 'styled-components'
import { API_FORM_REQUEST_URL } from 'utils'
import { trackButtonClick } from 'utils/tracking'

const TextContainer = styled.div`
    padding: 5rem 22vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding: 5rem 0;
    }
`

const StyledTitle = styled(TextRebrandHeader)`
    font-size: 48px !important;
    line-height: 4rem !important;
    max-width: 1000px;
`

const StyledButtons = styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
`

export const WorryFreeSection: FC = () => {
    const openContactSales = () => {
        trackButtonClick('contact-sales-button-clicked')
        window.open(API_FORM_REQUEST_URL)
    }

    const [push] = useLanding()
    const openDocumentation = () => {
        trackButtonClick('documentation-button-clicked')
        push('/docs')
    }
    return (
        <div>
            <TextContainer>
                <StyledTitle color={'black'} component={'h2'}>
                    Take your app to the next level with the Epidemic Sound
                    Partner API
                </StyledTitle>
                <StyledButtons>
                    <BlackButton
                        onClick={openContactSales}
                        backgroundColor={'black'}
                    >
                        Contact Sales
                    </BlackButton>
                    <GreyButton variant="secondary" onClick={openDocumentation}>
                        Documentation
                    </GreyButton>
                </StyledButtons>
            </TextContainer>
        </div>
    )
}
