import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NextPageWithLayout } from './_app'
import { smallScreenWidth, useInternal } from 'hooks/hooks'
import LandingPageLayout from 'layouts/Landingpage'
import { FullPageGrid, constants } from '@epidemicsound/design-system'
import { PageFooter } from 'components/pagefooter/landingpage/PageFooter'
import { IntroductionSection } from 'components/landingpage/sections/IntroductionSection'
import { UnlockSection } from 'components/landingpage/sections/UnlockSection'
import { WorryFreeSection } from 'components/landingpage/sections/WorryFreeSection'
import { FlowSection } from 'components/landingpage/sections/FlowSection'
import { TrustedBySection } from '../components/landingpage/sections/TrustedBySection'
import { useRouter } from 'next/router'

export const StyledLandingPageContainer = styled.section`
    margin-top: 2rem;
    background-color: var(--es-background-brand-off-white);
    margin-bottom: -100px;
    padding-bottom: 50px;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        overflow: hidden;
    }
`

export const StyledCol = styled(FullPageGrid.Col)`
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;

    span {
        font-size: 1rem;
    }
`

export const StyledSection = styled.section`
    display: flex;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-wrap: wrap;
    }
`

const SectionSpacing = styled.section`
    &:not(:first-child) {
        padding-top: 5rem;
    }

    @media only screen and (min-width: 1920px) {
        padding-left: 15vw;
        padding-right: 15vw;
    }

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
`

const UnauthorizedSection = styled.section`
    padding-top: 5.5rem;
    padding-bottom: 1.5rem;
    background-color: ${constants.COLORS.COMPLEMENTARY_YELLOW};
    color: ${constants.COLORS.BLACK};
    text-align: center;
`

const Landing: NextPageWithLayout = () => {
    const [push] = useInternal()
    const [authErrorMessage, setAuthErrorMessage] = useState(false)

    const router = useRouter()

    useEffect(() => {
        if (
            process.env.NEXT_PUBLIC_CONTENT_ADMIN_URL &&
            location.host.includes(process.env.NEXT_PUBLIC_CONTENT_ADMIN_URL)
        ) {
            push('/login')
        }
        if (router.isReady && router.query.error) {
            setAuthErrorMessage(true)
        }
    }, [router.isReady])

    return (
        <StyledLandingPageContainer data-cy-landing-page-container>
            {authErrorMessage && (
                <UnauthorizedSection>
                    You are not authorized to login to Epidemic Sound Developers
                    portal. If you need access, please contact{' '}
                    <a href="partner-api@epidemicsound.com">
                        partner-api@epidemicsound.com
                    </a>
                    .
                </UnauthorizedSection>
            )}
            <IntroductionSection />
            <SectionSpacing>
                <TrustedBySection />
            </SectionSpacing>
            <SectionSpacing>
                <UnlockSection />
            </SectionSpacing>
            <SectionSpacing>
                <FlowSection />
            </SectionSpacing>
            <WorryFreeSection />
            <SectionSpacing>
                <PageFooter />
            </SectionSpacing>
        </StyledLandingPageContainer>
    )
}

Landing.getLayout = (page) => <LandingPageLayout>{page}</LandingPageLayout>

export default Landing
